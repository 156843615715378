import axiosIns from "@/libs/axios"

export default {
    namespaced: true,
    state:{
       user_profile:null, 
       googleMultipleAccount:null,
    },
    getters:{
      getGoogleMultipleAccount(state){
          return state.googleMultipleAccount ;
      },
    },
    mutations:{
      SET_USER_PROFILE(state, payload) {
        state.user_profile = payload
      },

      SET_GOOGLE_MULTIPLE_ACCOUNT(state, payload){
        state.googleMultipleAccount = payload;
      }

    },
    actions:{
      //setGoogleMultipleAccount (ctx, payload){
            // ctx.commit('SET_GOOGLE_MULTIPLE_ACCOUNT', payload)
      // }
      GoogleLogin(ctx, token) {
            return new Promise((resolve, reject) => {
              axiosIns.get('/google/data/?access_token=' + token).then(response => {
                if (response) {
                ctx.commit('SET_GOOGLE_MULTIPLE_ACCOUNT', response.data.data)
                  resolve(response)
                }
                else reject(response)
              }).catch(err => reject(err))
            })
          },

        login(ctx, payload) {
            return new Promise((resolve, reject) => {
              axiosIns.post('/support/login', payload).then(response => {
                if (response.data.result.data) {
                  // ctx.commit('SAVE_LOGIN_INFO', response.data.result.data)
                  resolve(response.data.result.data)
                } 
                else reject(response)
              }).catch(err => reject(err))
            })
          },


          signUp(ctx, payload) {
            return new Promise((resolve, reject) => {
              axiosIns.post('/subscription/company/signup', payload).then(response => {
                console.log('response: ', response)
                resolve(response)
              }).catch(err => reject(err))
            })
          },

          forgotPassword(ctx, email) {
            return new Promise((resolve, reject) => {
              axiosIns.post('/forgot/agent/password/', { email }).then(response => {
                console.log('response : ', response)
                if (Number(response.data.result.code) === 200) resolve()
                else throw new Error('Error')
              }).catch(err => reject(err))
            })
          },

          updateProfile(ctx, payload){
            return new Promise((resolve, reject) => { 
              axiosIns.post('/update/user/',payload).then(response =>{
                console.log('api call update profile', payload)
                resolve(response)

              }).catch(error => reject(error))
            })
          },
          getProfile(ctx){
            return new Promise((resolve, reject) => { 
              axiosIns.get('/subscription/get/user/').then(response =>{
                ctx.commit('SET_USER_PROFILE', response.data.data)
                resolve(response)

              }).catch(error => reject(error))
            })
          },
          updateGeneralSetting(ctx, payload){
            return new Promise((resolve, reject) => { 
              axiosIns.post('/subscription/update/user/',payload).then(response =>{
                resolve(response)

              }).catch(error => reject(error))
            })
          },
          extendPlan(ctx, payload){
            return new Promise((resolve, reject) => { 
              axiosIns.post('/subscription/payment/', payload).then(response =>{
                resolve(response)

              }).catch(error => reject(error))
            })
          },
          getTransactionStatus(ctx,token){
            return new Promise((resolve, reject) => { 
              console.log('token', token);
              //axiosIns.get('https://b.m91.link/subscription/transaction-status?token=5SU89461UE246191Y', { headers: {"Authorization": `Bearer ${token}` }}).then(response =>{
              axiosIns.get('https://b.m91.link/subscription/transaction-status?token=' + token).then(response =>{

                resolve(response)

              }).catch(error => reject(error))
            })
          },
    }
}