import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'SubscribeDashboard',
      component: () => import('@/views/SubscribeDashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },


    {
      path: '/google/login/:token',
      name: 'GoogleWithLogin',
      component: () => import('@/views/GoogleWithLogin.vue'),
      meta: {
        layout: 'full',
      },
    },


    {
      path: '/subscription-plan',
      name: 'subscription-plan',
      component: () => import('@/views/SubscriptionPlan.vue'),
      meta: {
        pageTitle: 'Subscription Plan',
        breadcrumb: [
          {
            text: 'Subscription Plan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },


    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/Signup.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/update-profile',
      name: 'update-profile',
      component: () => import('@/views/UpdateProfile.vue'),
      meta: {
        pageTitle: 'Update Profile',
        breadcrumb: [
          {
            text: 'Update Profile',
            active: true,
          },
        ],
      },
    },

    {
      path: '/faqs',
      name: 'faqs',
      component: () => import('@/views/Faqs.vue'),
      meta: {
        pageTitle: 'FAQ',
        breadcrumb: [
          {
            text: 'FAQ',
            active: true,
          },
        ],
      },
    },
    {
      path: '/transactions',
      name: 'transactions',
      component: () => import('@/views/Transactions.vue'),
      meta: {
        pageTitle: 'Transactions',
        breadcrumb: [
          {
            text: 'Transactions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/transaction-status',
      name: 'transactions',
      component: () => import('@/views/TransactionStatus.vue'),
      meta: {
        pageTitle: 'Transactions Status',
        
          layout: 'full',
        
      },
    },

  

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
