import axiosIns from "@/libs/axios"
export default {
    namespaced: true,
    state:{
      subscriptionInfo:null,
      dashboardStats : null,
      userInfo:null,
      appInfo:null,
      subscribePlans:null,
    },
    getters:{
      getSubscriptionInfo(state){
          return state.subscriptionInfo ;
      },
      getDashboardStats(state){
        return state.dashboardStats
      },
      getAppInfo(state){
        return state.appInfo
      },
      getUserInfo(state){
        return state.userInfo
      },

      getSubscribePlans(state){
        return state.subscribePlans
      }

      
    },
    mutations:{
       updateSubscriptionInfo(state, payload ){
         state.appInfo = payload.apps;
        // split(',') // imageList:  []
         state.userInfo = payload.user_info;
         state.subscriptionInfo=payload.subscription
       },

       updatePricePlanInfo( state, payload){
      state.subscribePlans = payload

       }

    },
    actions:{
      fetchSubscribeDashboard(ctx){
        return new Promise((resolve, reject)=>{
          axiosIns.get('/subscription/apps/list/').then((response) =>{
          ctx.commit('updateSubscriptionInfo', response.data.data)
            resolve();
          })
        .catch(error => reject(error))
      })
      },


      fetchSubscribePlanPrice(ctx){
        return new Promise((resolve, reject)=>{
          axiosIns.get('/subscription/plans/list/').then((response)=>{
            ctx.commit('updatePricePlanInfo', response.data)
            resolve(response);
          })
          .catch(error => reject(error))
        })
}



    }
}